import { getCurrentClinic } from '@/utils/localStorageManager'
import { cpf } from 'cpf-cnpj-validator'

const companionMixin = () => ({
  data: () => ({
    clinic: getCurrentClinic(),
    cpf: cpf,
    keys: ['name', 'cpf', 'rg', 'email', 'phone', 'cellphone'],
  }),
  methods: {
    async findCompanion() {
      try {
        const { data } = await this.api.findSurgeryCompanion(
          this.surgeryInformationId
        )
        if (!data) return
        this.companion = {
          ...data,
          address: {
            ...data.address ?? this.companion?.address,
          },
          cpf: cpf.format(data.cpf),
        }
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async saveCompanion() {
      if (this.companion.id) {
        await this.updateSurgeryCompanion()
        return
      }
      await this.createSurgeryCompanion()
    },
    async createSurgeryCompanion() {
      if (!this.canSave) return
      try {
        const { data } = await this.api.createSurgeryCompanion({
          surgery_information_id: this.surgeryInformationId,
          patient_id: this.patientId,
          ...this.companion
        })
        this.companion = {
          ...this.companion,
          data
        }
        this.$set(this.companion, 'id', data.id)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async updateSurgeryCompanion() {
      if (!this.canSave) return
      try {
        const dataForm = new FormData()
        if (typeof this.companion?.picture !== 'string' && this.companion?.picture) {
          dataForm.append('picture', this.companion.picture)
        }

        this.keys.map(key => {
          if (this.companion[key] !== undefined)
            dataForm.append(key, this.companion[key])
        })

        dataForm.append('address', JSON.stringify(this.companion.address))
        dataForm.append('clinic_id', this.clinic.id)
        dataForm.append('surgery_information_id', this.surgeryInformationId)
        const { data } = await this.api.updateSurgeryCompanion(
          this.companion.id,
          dataForm
        )
        this.$set(this.companion, 'picture', data.picture)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  }
});

export default companionMixin;