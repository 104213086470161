<template>
  <b-modal
    id="companion-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="clearModal"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Identificação do acompanhante</p>
      </div>
      <div>
        <Close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <section>
        <GeneralData :form="companion" type="acompanhante" />
        <hr />
      </section>

      <section>
        <ContactData :form="companion" type="acompanhante" />
      </section>

      <section>
        <AddressData :form="companion" />
        <hr />
      </section>

      <section>
        <b-container fluid>
          <h3>Observações gerais</h3>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="observations">Observações gerais</label>
                <TextArea
                  id="observations"
                  v-model="companion.observations"
                  placeholder="Descrever"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <div class="wrapper-button">
        <!-- A ser implementado
        <b-button variant="outline-primary" class="mr-2">
          Imprimir ficha
        </b-button> -->
        <b-button variant="primary" @click="saveCompanionMethod">
          Salvar ficha
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import companionMixin from '@/mixins/SurgeryCenter/companionMixin'

export default {
  name: 'CompanionModal',
  props: {
    companionProp: {
      type: Object,
      default: () => ({})
    },
    patientId: String,
    surgeryInformationId: String,
    updateSurgeryRecord: Function
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    GeneralData: () => import('./FormData/GeneralData'),
    ContactData: () => import('./FormData/ContactData'),
    AddressData: () => import('./FormData/AddressData'),
    TextArea: () => import('@/components/General/TextArea')
  },
  created() {
    this.companion = this.defaultForm()
  },
  data: () => ({
    companion: {},
    canSave: true
  }),
  mixins: [
    companionMixin('canEdit', 'surgeryInformationId', 'companion', 'patientId')
  ],
  methods: {
    async onShow() {
      const isLoading = this.$loading.show()
      await this.findCompanion()
      isLoading.hide()
    },
    defaultForm() {
      return {
        name: '',
        cpf: '',
        rg: '',
        email: '',
        cellphone: '',
        phone: '',
        address: {
          zipCode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        },
        observations: ''
      }
    },
    async saveCompanionMethod() {
      const isLoading = this.$loading.show()
      try {
        await this.updateSurgeryRecord('Preenchido')
        await this.saveCompanion()
        this.$toast.success('Acompanhante salvo com sucesso')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    clearModal() {
      this.companion = this.defaultForm()
      this.$bvModal.hide('companion-modal')
    }
  }
}
</script>

<style lang="scss">
#companion-modal {
  h3 {
    color: var(--blue-500);
    font-weight: 700;
    font-size: min(2.6vw, 18px);
  }

  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 24px 24px 0 24px;

        .orientation {
          color: var(--type-active);
          font-weight: 600;
          font-size: min(1.2vw, 16px);
        }
      }
      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
